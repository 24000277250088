import React from 'react';
import PropTypes from 'prop-types';
import style from 'styles/components/Help.module.scss';

const Help = ({ wClass, children }) => {
  const info = {
    'mack': {
      phone: '888-549-1579',
      hours: 'Mon-Fri: 8am - 8pm EST'
    },
    'volvo': {
      phone: '888-548-9120',
      hours: 'Mon-Fri: 8am - 8pm EST'
    },
    'trux': {
      phone: '000-000-000',
      hours: 'Mon-Fri: 8am - 8pm EST'
    },
    'ryder': {
      phone: '888-743-0818',
      hours: 'Mon-Fri: 8am - 8pm EST'
    }
  }
  const phone = info[process.env.THEME].phone;
  const hours = info[process.env.THEME].hours;

  return (
  <div className={`${style.help} ${wClass} Help`}>
    <div>
      <div className={style.info}>
        <div className={style.title}>
          Need Help? Let's Talk. 
        </div>
        <div className={style.number}>
          <strong>{phone}</strong>
          <span>{hours}</span>
        </div>
      </div>
      <div className={style.brand}>
        <div className={style.text}>&nbsp;</div>
        <div className={style.logo}>&nbsp;</div>
      </div>
    </div>
  </div>
)};

Help.propTypes = {
  wClass: PropTypes.string,
  children: PropTypes.node,
};

Help.defaultProps = {
};

export default Help;
