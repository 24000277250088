import React from 'react';
import { Link, Element } from 'react-scroll';

const TermsRyder = () => {

  return (
        <section>
          <h1>Terms of Use.</h1>
          <p>Effective Date: November 1, 2021<br />Last Updated: November 1, 2021</p>

          <ul>
            <li><Link activeClass="active" to="acceptance" spy={true} smooth={true} offset={-75} duration={500}>Attention – Acceptance of Our Terms</Link></li>
            <li><Link activeClass="active" to="use_of_service" spy={true} smooth={true} offset={-75} duration={500}>Use of Services</Link></li>
            <li><Link activeClass="active" to="warranties" spy={true} smooth={true} offset={-75} duration={500}>Warranties</Link></li>
            <li><Link activeClass="active" to="limitation_of_liability" spy={true} smooth={true} offset={-75} duration={500}>Disclaimer and Limitation of Liability</Link></li>
            <li><Link activeClass="active" to="indemnity" spy={true} smooth={true} offset={-75} duration={500}>Indemnity</Link></li>
            <li><Link activeClass="active" to="ownership" spy={true} smooth={true} offset={-75} duration={500}>Ownership of the Site and its Content</Link></li>
            <li><Link activeClass="active" to="links" spy={true} smooth={true} offset={-75} duration={500}>Links to Other Materials</Link></li>
            <li><Link activeClass="active" to="products" spy={true} smooth={true} offset={-75} duration={500}>Certain Insurance Products</Link></li>
            <li><Link activeClass="active" to="point_of_sale" spy={true} smooth={true} offset={-75} duration={500}>Point of Sale Terms and Conditions</Link></li>
            <li><Link activeClass="active" to="payment" spy={true} smooth={true} offset={-75} duration={500}>Payment</Link></li>
            <li><Link activeClass="active" to="fraud" spy={true} smooth={true} offset={-75} duration={500}>Fraud Statements </Link></li>
            <li><Link activeClass="active" to="terminating" spy={true} smooth={true} offset={-75} duration={500}>Terminating Our Services</Link></li>
            <li><Link activeClass="active" to="governing_law" spy={true} smooth={true} offset={-75} duration={500}>Governing Law; Jurisdiction and Venue</Link></li>
            <li><Link activeClass="active" to="general" spy={true} smooth={true} offset={-75} duration={500}>General Information</Link></li>
          </ul>

          <h2><Element name="acceptance">Attention - Acceptance of Our Terms</Element></h2>
          <p>“We”, “our”, “us,” “Company,” or “REIN”, means REIN Technologies (US) Inc., its subsidiaries including REIN Connected Insurance Agency, LLC, and domain names we use. “You” or “your” means you or the person visiting our websites and/or using our products and services. Our “Services” mean (a) products and services we sell online, and (b) website information or tools we provide on REIN.ai, ryder.reinconnectedinsurance.com, or any other of our affiliated websites, and any applications we develop, whether independently or in collaboration with third parties, for the web, Android or iOS (collectively, our “Sites”). </p>
          <p>These Terms of Use (or these “Terms”) present the basis on which you are permitted to access and use our Sites and our Services. Please read these Terms carefully before using our Sites. By accessing and using our Sites in any way, including without limitation browsing our Sites, you agree to and are bound by these Terms. If you do not agree to all of these Terms, do not use our Sites in any manner. You acknowledge and agree that, by accessing or using our Services, or by downloading any content from our Sites, you have read and you are bound by these Terms.</p>
          <p>By agreeing to these Terms, you agree to the terms of our <a href="/privacy">Privacy Policy</a>, which is expressly incorporated herein. Before using any of the Sites, please carefully review our Privacy Policy. All Personal Information provided to us as a result of your use of one of the Sites will be handled in accordance with our Privacy Policy.</p>
          <p>Please note that we reserve the right, at our sole discretion, to modify these Terms or our Services at any time and without prior notice to you. You can visit this page at any time to review the current form of our Terms.</p>
          <p>If you accept or agree to these Terms on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these Terms, and in such event “you” and “your” will refer and apply to that company or other legal entity.</p>
          <p>These Terms apply to your use of our Services as well as any additional services and/or products that we may make available from time to time. Not all of the Services described on our Sites are available in all areas of the United States, and you may not be eligible for them. We reserve the right to determine eligibility.  You agree that we may, in our sole discretion and at any time without prior notice to you: (i) discontinue providing or limit access to our Sites and/or to our Services; or (ii) terminate or limit your access to or use of our Sites. If, for example, we believe you have engaged in conduct prohibited by these Terms, we will terminate or limit your access to or use of our Sites. </p>
          <h2><Element name="use_of_service">Use of Services</Element></h2>
          <p>You represent and warrant that you are at least 18 years of age and that you possess the legal right and ability to enter into these Terms. You agree not to use our Sites for any unlawful or abusive purpose or in any way which interferes with our ability to make our Sites available to our customers, or damages our property. You agree that your use of our Sites is subject to all applicable local, state, national and international laws and regulations. You also agree:</p>
          <ul>
            <li>to comply with US law and local laws or rules regarding online conduct and acceptable content;</li>
            <li>not to host, submit content to or use our Sites without the consent of a parent, guardian or educational supervisor if you are under the age of 18 (a “Minor”);</li>
            <li>not to use any robot, spider, scraper or other automatic device, process or means to access our Sites for any purpose without our express written permission;</li>
            <li>not to reproduce, duplicate, copy, sell, trade, resell, distribute or exploit, any part of our Sites, or to access or use our Services, for any commercial purposes that are not expressly permitted by these Terms;</li>
            <li>not to take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large load on our infrastructure;</li>
            <li>not to upload or transmit viruses or other harmful, disruptive or destructive files;</li>
            <li>not to undertake, cause, permit or authorize any modification, creation of derivative works, translation, reverse engineering, decompiling, disassembling or hacking of all or part of our Services or Content (as defined below) or otherwise attempt to use or access our Sites or any of our Services other than as intended; not to disrupt, interfere with, or otherwise harm or violate the security of our Sites, or any services, system resources, servers or networks connected to or accessible through our Sites; and</li>
            <li>not to pretend that you are or represent someone else, or impersonate any other individual or entity. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through our Sites.</li>
          </ul>
          <p>In order to access our Services, you will need to create an account. It is a condition of use of our Services that all the details you provide to us are correct, current and complete and that you will update such details as needed to ensure the same. If we believe that the details you provide (including but not limited to Personal Information as defined in our Privacy Policy) are inaccurate, fraudulent, incomplete, or otherwise violate these Terms, we have the right to refuse you access to our Services and to terminate or suspend your account, as applicable. As a registered User, you will have a username and password. You are responsible for safeguarding the password to access your Account. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your Account, whether or not you have authorized such activities or actions. You will immediately notify us of any unauthorized use of your Account to which you are, or should reasonably have been, aware. We will not be responsible if you do not properly secure your password or if you choose to share your password with anyone else.</p>
          <p>You may have the ability to make payments in connection with our Services through our Sites (a “Transaction”).  If you wish to make a Transaction, you may be asked to supply certain relevant information such as your bank account number, credit card number and its expiration date and your billing address.  YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT TO USE ANY SUCH BANK ACCOUNT OR CREDIT CARD THAT YOU SUBMIT IN CONNECTION WITH A TRANSACTION.  By submitting such information, you grant to REIN the right to provide such information to third parties for purposes of facilitating Transactions.  You agree to pay all charges incurred by you or on your behalf through the Sites, including any taxes you may be responsible for in connection with your Transaction.</p>
          <h2><Element name="warranties">Warranties</Element></h2>
          <p>WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIS SITE OR ITS CONTENT, OR, EXCEPT AS PROVIDED BELOW, ANY PRODUCT OR SERVICE AVAILABLE ON OR PROMOTED THROUGH THIS SITE. THIS SITE AND ALL OF ITS CONTENT (INCLUDING USER-GENERATED CONTENT) ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE” BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, We and our AFFILIATES, AND OUR SERVICE PROVIDERS AND LICENSORS DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, ARISING BY STATUTE, CUSTOM, COURSE OF DEALING, COURSE OF PERFORMANCE OR IN ANY OTHER WAY, WITH RESPECT TO THIS SITE, ITS CONTENT, AND ANY PRODUCTS OR SERVICES AVAILABLE OR PROMOTED THROUGH THIS SITE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, we and our AFFILIATES, AND OUR SERVICE PROVIDERS AND LICENSORS DISCLAIM ALL REPRESENTATIONS AND WARRANTIES (A) OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE; (B) RELATING TO THE SECURITY OF THIS SITE; (C) THAT THE CONTENT OF THIS SITE IS ACCURATE, COMPLETE OR CURRENT; OR (D) THAT THIS SITE WILL OPERATE SECURELY OR WITHOUT INTERRUPTION OR ERROR.</p>

          <p>WE DO NOT REPRESENT OR WARRANT THAT THIS SITE, ITS SERVERS, OR ANY TRANSMISSIONS SENT FROM US OR THROUGH THIS SITE WILL BE FREE OF ANY HARMFUL COMPONENTS (INCLUDING VIRUSES).</p>

          <p>WE do not endorse and ARE not responsible for statements, advice or opinions made by anyone other than OUR authorized spokespersons. WE DO NOT ENDORSE AND ARE NOT RESPONSIBLE FOR ANY statements, advice or opinions CONTAINED IN USER-GENERATED CONTENT AND SUCH statements, advice or opinions DO NOT IN ANY WAY REFLECT OUR STATEMENTS, ADVICE or OPINIONS.</p>

          <p>WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES AGAINST THE POSSIBILITY OF DELETION, MISDELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS, OR OTHER DATA.</p>

          <p>YOU ACCEPT THAT OUR SHAREHOLDERS, OWNERS, OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS AND OTHER REPRESENTATIVES SHALL HAVE THE BENEFIT OF THIS CLAUSE.</p>
          <p>Some jurisdictions do not allow the disclaimer of implied warranties. In such jurisdictions, the foregoing disclaimers may not apply to you insofar as they relate to implied warranties.</p>
          <h2><Element name="limitation_of_liability">Disclaimer and Limitation of Liability</Element></h2>
          <p>IN CONSIDERATION FOR YOUR USE OF AND ACCESS TO OUR SITES, AND TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY DAMAGES, INCLUDING BUT NOT LIMITED TO DIRECT, COMPENSATORY, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION REGARDLESS OF WHETHER ANY COMPANY AFFILIATE HAS BEEN INFORMED OF THE LIKELIHOOD OF SUCH DAMAGES) ARISING OUT OF OR RELATING TO THE PROVISION OF OUR SERVICES OR OUR SITE.</p>
          <p>YOU AND REIN AGREE THAT THIS SECTION, “DISCLAIMER AND LIMITATION OF LIABILITY”, IS AN AGREED ALLOCATION OF RISK BETWEEN YOU AND REIN. YOU ACKNOWLEDGE THAT, ABSENT YOUR AGREEMENT TO THIS LIMITATION OF LIABILITY, REIN WOULD NOT MAKE THE SITE AVAILABLE TO YOU. THIS LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW AND SHALL SURVIVE TERMINATION OF THIS AGREEMENT.</p>
          <h2><Element name="indemnity">Indemnity</Element></h2>
          <p>YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS THE COMPANY, FROM AND AGAINST ANY AND ALL CLAIMS, EXPENSES OR DAMAGES (INCLUDING ATTORNEYS’ FEES), WHETHER KNOWN OR UNKNOWN, ARISING FROM, INCURRED AS A RESULT OF, OR IN ANY MANNER RELATED TO (A) YOUR USE OR MISUSE OF OUR SERVICES AND/OR OUR SITES, OR (B) YOUR BREACH OR OTHER VIOLATION OF THESE TERMS. YOU HEREBY AGREE TO WAIVE (TO THE EXTENT PERMISSIBLE) ALL LAWS THAT MAY LIMIT THE EFFECTIVENESS OF THE FOREGOING RELEASES. NOTWITHSTANDING THE FOREGOING, YOU SHALL NOT BE LIABLE FOR CLAIMS, EXPENSES OR DAMAGES ARISING FROM THE INTENTIONAL OR GROSSLY NEGLIGENT ACTS OF ANY THE COMPANY AFFILIATES. THIS INDEMNIFICATION SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW AND SHALL SURVIVE TERMINATION OF THIS AGREEMENT.</p>
          <h2><Element name="ownership">Ownership of the Site and its Content</Element></h2>
          <p>Our Sites, and all information, materials and content available on our Sites, including without limitation any trademarks, logos, service marks, trade dress, text, and graphics (collectively, "Content"), is the property of REIN and/or certain third-parties. The Content is protected by United States copyright, trade dress, patent, and trademark laws, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. All Site Content is the copyrighted property of REIN or its subsidiaries or affiliated companies and/or third parties, except for User content, to which Users retain their respective copyrights. All trademarks, service marks, and trade names are proprietary to REIN or its affiliates and/or third parties. Nothing contained on the Site should be construed as granting, by implication or otherwise, any license or right to use any of the Content without the written permission of the party that owns the Content, whether it be REIN or a third party. You agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the Content, and nothing herein shall be interpreted to grant you any right or license under any intellectual property rights of REIN or any third party.</p>
          <h2><Element name="links">Links to Other Materials</Element></h2>
          <p>The sites linked to our Sites, if any, are not necessarily under the control of REIN and REIN is not responsible for the content of any linked site, including any social media site. Any links included in the Site have been selected by REIN for your convenience. The selection or omission of links is not intended to endorse any particular companies or products. If you decide to access any of the third party sites linked to our Sites, you do so entirely at your own risk. REIN will not be responsible for any interactions between you and the operators or other users of such websites, or any damages incurred by you as a result of your use of such websites</p>
          <h2><Element name="products">Certain Insurance Products</Element></h2>
          <p>We are not an insurance company.  Insurance Services are provided by REIN Connected Insurance Agency, LLC and policies made available through the Ryder Insurance Solutions program are underwritten by our insurance carrier partner(s) licensed to sell insurance in your state.   In providing the insurance Services, REIN Connected Insurance Agency, LLC acts as an agent for our insurance carrier partner(s) pursuant to the terms of a separate agreement.  View our licensing information <a href="/licensing">here</a>.  </p>
          <p>When you purchase insurance using our Services, the terms and conditions of such insurance, including, but not limited to, coverage, exclusions, limitations, reductions in benefits and terms under which it may be continued in force or discontinued, are set forth in your insurance policy, which is an agreement between you and the insurance carrier. Further, some insurance products and/or services may not be available in all states or territories. Any insurance purchasing decisions such as coverage amounts are completely and solely the responsibility of the insured. REIN is not liable for any inaccurate, missing or misconstrued information on its Sites and makes no guarantee as to the quality and precision of the content. REIN may at its sole discretion change the content and information on its Sites from time to time. At the time of a claim, coverage will be determined by the insurance carrier in accordance with the terms and conditions of the insurance policy and not the Sites.</p>
          <h2><Element name="point_of_sale">Point of Sale Terms and Conditions</Element></h2>
          <p>All representations herein and in your account and coverage application are declared to be true and complete to the best of your knowledge and you represent that no information has been withheld or suppressed. You agree that your application and the terms and conditions of your insurance policy shall be the basis of any contract between you and the insurer. You hereby authorize the insurer to investigate all representations contained herein and in your coverage application.</p>
          <p>Insurance will not be bound until a signed application and required premium payment have been received from you, and you are in receipt of written confirmation from us, your insurance agent (also known as an “insurance producer”), or from the insurer, indicating that the policy has been bound.  The insurance policy will be effective as of the effective date reflected on the applicable policy documents.</p>
          <p>SUBMISSION OF YOUR APPLICATION DOES NOT COMMIT THE INSURER TO ANY LIABILITY NOR MAKE YOU LIABLE FOR ANY PREMIUM UNLESS AND UNTIL THE INSURER AGREES TO BIND THIS INSURANCE.</p>
          <p>YOU FULLY UNDERSTAND THAT THE POLICY WILL INCLUDE TERMS, CONDITIONS AND EXCLUSIONS WITH RESPECT TO THE INSURANCE AFFORDED. Any questions about the insurance afforded under this program should be directed to us or the insurer.</p>
          <p>The Uniform Computer Information Transactions Act or any version thereof adopted by any state in any form (“UCITA”) shall not apply to this Agreement. To the extent that UCITA is applicable, the Parties agree to opt-out of its applicability pursuant to its provisions.</p>
          <h2><Element name="payment">Payment</Element></h2>
          <p>Payment for certain insurance products available on our Sites may be made with selected credit cards. These payments are processed by a third-party payment processing service (“Payment Service”). Any payment made using the Payment Service will be deemed complete only when:</p>
          <p>(a) you have submitted your name and the details of a current and valid credit card of a type that the Payment Service accepts, and all other information required by the Payment Service (the “Information”);</p>
          <p>(b) all Information from you has been received by the Payment Service;</p>
          <p>(c) a charge against your selected credit card has been made; and</p>
          <p>(d) we have received full payment for all applicable amounts due for the policy.</p>
          <p>IF ANY PAYMENT IS REVERSED OR DISHONORED FOR ANY REASON WHATSOEVER, NO PAYMENT WILL BE DEEMED TO HAVE BEEN MADE TO US, AND WE OR THE INSURER MAY RESCIND OR CANCEL YOUR POLICY IN ACCORDANCE WITH ITS TERMS.</p>
          <p>We may, in our sole discretion and at any time, change the credit cards or other payment methods that we accept.</p>
          <p>We make no warranties, representations or guarantees that the Payment Service will operate as intended, remain secure, or that its operation will be continuous or be uninterrupted. All implied terms, conditions and warranties regarding the functionality and security of the Payment Service are disclaimed to the fullest extent possible.</p>
          <h2><Element name="fraud">Fraud Statements</Element></h2>
          <p>ARKANSAS, MARYLAND, and NEW MEXICO:  Any purpose who knowingly (or willfully in MD) presents a false claim for payment of a loss or benefit or knowingly (or willfully in MD) presents false information in an application for insurance is guilty of a crim and may be subject to fines and confinement in prison. </p>
          <p>COLORADO:  It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado Division of Insurance within the Department of Regulatory Agencies.</p>
          <p>FLORIDA: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, files a statement of claim or an application containing any false, incomplete or misleading information is guilty of a felony of the third degree.</p>
          <p>KANSAS: Any person who, knowingly and with intent to defraud, presents, causes to be presented or prepares with knowledge or belief that it will be presented to or by an insurer, purported insurer, broker or any agent thereof, any written statement as part of, or in support of, an application for the issuance of, or the rating of an insurance policy for personal or commercial insurance, or a claim for payment or other benefit pursuant to an insurance policy for commercial or personal insurance which such person knows to contain materially false information concerning any fact material thereto; or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act.</p>
          <p>MAINE, TENNESSEE and WASHINGTON:  It is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company.  Penalties may include imprisonment, fines, and denial of insurance benefits. </p>
          <p>MASSACHUSETTS: If you or someone else on your behalf gives us false, deceptive, misleading, or incomplete information that increases our risk of loss, we may refuse to pay claims under any or all of the Optional Insurance Parts and we may cancel your policy. Such information includes the description and the place of garaging of the vehicle(s) to be insured, the names of the operators required to be listed and the answers to the questions in this application about all listed operators.  Check to make certain that you have correctly listed all operators and the completeness of their driving records.  The Merit Rating Board may verify the accuracy of the previous driving records of all listed operators, include that of the applicant for this insurance. </p>
          <p>NEW JERSEY:  Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.</p>
          <p>NEW YORK: Any person who knowingly and with intent to defraud any insurance company or other person files an application for commercial insurance or a statement of claim for any commercial or personal insurance benefits containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, and any person who, in connection with such application or claim, knowingly makes or knowingly assists, abets, solicits or conspires with another to make a false report of the theft, destruction, damage or conversion of any motor vehicle to a law enforcement agency, the department of motor vehicles or an insurance company, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars ($5,000) and the value of the subject motor vehicle or stated claim for each violation. </p>
          <p>OKLAHOMA: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.</p>
          <p>OREGON and RHODE ISLAND: Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty (may be guilty in OR) of a crime and may be subject to fines and confinement in prison.</p>
          <p>PENNSYLVANIA: Any person who knowingly and with intent to injure or defraud or deceive any insurer files an application or claim containing any false, incomplete or misleading information shall, upon conviction, be subject to imprisonment for up to seven years and payment of a fine up to $15,000.</p>
          <p>ALL OTHER STATES: Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals, for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime and subjects that person to criminal and civil penalties. </p>
          <h2><Element name="terminating">Terminating Our Services</Element></h2>
          <p>By Us:  We, in our sole discretion and for any reason or no reason, may terminate your account for our Services, disable your access to our Service (or any part thereof), discontinue a Service, or terminate any license or permission granted to you hereunder, at any time, with or without notice. You agree that REIN shall not be liable to you or any third-party for any such termination. Without limiting the generality of the foregoing, we may terminate your account in cases of actual or suspected fraud, or violations of these Terms or other laws or regulations, and any suspected fraudulent, abusive, or illegal activity may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies we may have at law or in equity. </p>
          <p>By You:  If you are dissatisfied with our Services, please let us know by e-mailing us at Info@rein.com.   Please note that email communications will not necessarily be secure, and sensitive information, such as bank account or credit card information, should not be included.  Your only remedy with respect to any dissatisfaction with (i) our Services, (ii) any of these Terms, (iii) any policy or practice of Company in operating our Services, or (iv) any content or information transmitted or made available through our Services, is to terminate your use of our Services.</p>
          <h2><Element name="governing_law">Governing Law; Jurisdiction and Venue</Element></h2>
          <p>These Terms (this “Agreement”) shall be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts, excluding that body of law applicable to conflicts of law. You agree that any suit, action or proceeding arising out of or relating to this Agreement shall be instituted only in a state or federal court sitting in Suffolk County, Massachusetts. You hereby waive any objection you may have now or hereafter to the laying of the venue of any such suit, action or proceeding, and irrevocably submit to the jurisdiction of any such court in any such suit, action or proceeding.</p>
          <h2><Element name="general">General Information</Element></h2>
          <p>We may change or modify these Terms from time to time. Please visit this page from time to time to review our then current Terms because they are binding on you. We may assign all or part of our rights or duties under these Terms in connection with a sale of all or substantially all the assets of REIN (including the Site and operations) to a third party. You may not assign this Agreement without our prior written consent. If any part of this Agreement is held invalid or unenforceable, that portion shall be construed to reflect the parties’ original intent, and the remaining portions shall remain in full force and effect. This Agreement, together with our Privacy Policy, Point of Sale Terms and Conditions and any other legal notices published by us on our Sites, shall constitute the entire agreement between REIN and you with respect to your use of the Site, materials, and Content, and it supersedes all prior or contemporaneous communications and proposals between REIN and you with respect thereto. Our failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.  If you have a question or comment regarding our Services or Sites, please email info@rein.ai.  Please note that email communications will not necessarily be secure, and sensitive information, such as bank account or credit card information, should not be included.  </p>

            

          <p>Insurance services provided by REIN<br />  		© REIN Technologies (US) Inc. 2021 </p>

        </section>
  )
};

export default TermsRyder;
