import React from 'react';

import Layout from 'pages/_common/Layout';

import style from 'styles/pages/Thanks.module.scss';

const Thanks = () => {
  const names = {
    'mack': {
      name: 'Mack Integrated Insurance',
    },
    'volvo': {
      name: 'Volvo Connected Insurance',
    },
    'trux': {
      name: 'TRUX',
    },
    'ryder': {
      name: 'Ryder',
    },
  }
  const siteName = names[process.env.THEME].name;

  return (
    <Layout wClass={style.layout}>
      <div className={style.page}>
        <section>
          <span className={`material-icons ${style.icon}`}>check_circle</span>
          <h2>Thank you for your interest in {siteName}.</h2>
          <p>A representative will reach out to you shortly.</p>
        </section>
      </div>
    </Layout>
  )
};

export default Thanks;
