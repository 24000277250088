import React, { useEffect } from 'react';
import LogRocket from 'logrocket';

import { themeLinks } from 'utils/links';
import Maintenance from 'pages/Maintenance/';
import Routes from './routes';

const { 
  REACT_APP_TITLE, 
  REACT_APP_MAINTENANCE_MODE 
} = process.env;

function App() {
  LogRocket.init(themeLinks[process.env.THEME].logRocket); 

  useEffect(() => {
    document.title = REACT_APP_TITLE;
  }, []);

  return (
    <>
      {REACT_APP_MAINTENANCE_MODE === 'true' ? <Maintenance /> : <Routes />}
    </>
  )
}

export default App;
