export const themeLinks = {
    'mack': {
      tos: 'https://www.mackintegratedinsurance.com/terms-of-use',
      privacy: 'https://www.mackintegratedinsurance.com/privacy-policy',
      licensing: 'https://www.mackintegratedinsurance.com/licensing',
      logRocket: 'e7nezd/volvo-vio'
    },
    'volvo': {
      tos: 'https://www.volvoconnectedinsurance.com/terms-of-use',
      privacy: 'https://www.volvoconnectedinsurance.com/privacy-policy',
      licensing: 'https://www.volvoconnectedinsurance.com/licensing',
      logRocket: 'e7nezd/volvo-vio'
    },
    'trux': {
      tos: '',
      privacy: '',
      licensing: '',
      logRocket: 'e7nezd/volvo-vio'
    },
    'ryder': {
      tos: '/terms',
      privacy: '/privacy',
      licensing: '/licensing',
      logRocket: 'e7nezd/ryder'
    },
  }