import React from 'react';

const LicensingInformationRyder = () => {

  return (
        <section>
          <h1>Licensing Information</h1>
          
          <span>REIN Connected Insurance Agency, LLC</span>

          <table>
            <tr>
              <th>State</th>
              <th>License Number</th>
              <th>License Type</th>
            </tr>
            <tr>
              <td>AK</td>
              <td>100140617</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>AL</td>
              <td>781731</td>
              <td>Business Entity Producer</td>
            </tr>
            <tr>
              <td>AR</td>
              <td>100171990</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>AZ</td>
              <td>1800016199</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>CA</td>
              <td>0L70741</td>
              <td>Non Resident Insurance Producer</td>
            </tr>
            <tr>
              <td>CO</td>
              <td>530232</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>CT</td>
              <td>2549982</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>DC</td>
              <td>3000067609</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>DE</td>
              <td>3000067558</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>FL</td>
              <td>L100242</td>
              <td>Agency</td>
            </tr>
            <tr>
              <td>GA</td>
              <td>193687</td>
              <td>Principal Agency - Nonresident</td>
            </tr>
            <tr>
              <td>HI</td>
              <td>449991</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>IA</td>
              <td>1002286840</td>
              <td>Business Entity Producer</td>
            </tr>
            <tr>
              <td>ID</td>
              <td>606046</td>
              <td>Non Resident Producer</td>
            </tr>
            <tr>
              <td>IL</td>
              <td>3000067608</td>
              <td>Business Entity Producer</td>
            </tr>
            <tr>
              <td>IN</td>
              <td>3221060</td>
              <td>Producer - Organization (Corp)</td>
            </tr>
            <tr>
              <td>KS</td>
              <td>815055702-000</td>
              <td>Agency</td>
            </tr>
            <tr>
              <td>KY</td>
              <td>944833</td>
              <td>Agent</td>
            </tr>
            <tr>
              <td>LA</td>
              <td>722654</td>
              <td>Producer Agency</td>
            </tr>
            <tr>
              <td>MA</td>
              <td>2020059</td>
              <td>Business Entity Producer</td>
            </tr>
            <tr>
              <td>MD</td>
              <td>3000067600</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>ME</td>
              <td>AGN282575</td>
              <td>NR Producer Business Entity</td>
            </tr>
            <tr>
              <td>MI</td>
              <td>111896</td>
              <td>Non Resident Producer</td>
            </tr>
            <tr>
              <td>MN</td>
              <td>40512448</td>
              <td>Business Producer</td>
            </tr>
            <tr>
              <td>MO</td>
              <td>8399814</td>
              <td>Business Entity Producer</td>
            </tr>
            <tr>
              <td>MS</td>
              <td>15031051</td>
              <td>Business Entity Producer</td>
            </tr>
            <tr>
              <td>MT</td>
              <td>3000067586</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>NC</td>
              <td>1000515605</td>
              <td>Corporation</td>
            </tr>
            <tr>
              <td>ND</td>
              <td>3000067603</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>NE</td>
              <td>100263974</td>
              <td>Agency</td>
            </tr>
            <tr>
              <td>NH</td>
              <td>2362882</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>NJ</td>
              <td>1633918</td>
              <td>Producer - Organization (Corp)</td>
            </tr>
            <tr>
              <td>NM</td>
              <td>1800012342</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>NV</td>
              <td>3221013</td>
              <td>Non Res Producer/Producer Firm</td>
            </tr>
            <tr>
              <td>NY</td>
              <td>BR-1473006</td>
              <td>Insurance Broker</td>
            </tr>
            <tr>
              <td>OH</td>
              <td>1138696</td>
              <td>Non-Res Major Lines</td>
            </tr>
            <tr>
              <td>OK</td>
              <td>100285148</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>OR</td>
              <td>3000072533</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>PA</td>
              <td>803987</td>
              <td>NonResident Producer Agency</td>
            </tr>
            <tr>
              <td>RI</td>
              <td>3000067585</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>SC</td>
              <td>1912954137</td>
              <td>Agency</td>
            </tr>
            <tr>
              <td>SD</td>
              <td>10019480</td>
              <td>Business Entity</td>
            </tr>
            <tr>
              <td>TN</td>
              <td>2358462</td>
              <td>Business Entity Producer</td>
            </tr>
            <tr>
              <td>TX</td>
              <td>2175744</td>
              <td>General Lines Agncy/Agnt</td>
            </tr>
            <tr>
              <td>UT</td>
              <td>606103</td>
              <td>Non Resident Producer Organization</td>
            </tr>
            <tr>
              <td>VA</td>
              <td>141325</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>VT</td>
              <td>3221148</td>
              <td>Non Res Prod Bus Entity</td>
            </tr>
            <tr>
              <td>WA</td>
              <td>944637</td>
              <td>Insurance Producer</td>
            </tr>
            <tr>
              <td>WI</td>
              <td>3000067618</td>
              <td>Intermediary Firm</td>
            </tr>
            <tr>
              <td>WV</td>
              <td>100237793</td>
              <td>Business Entity</td>
            </tr>
            <tr>
              <td>WY</td>
              <td>331953</td>
              <td>Non Resident Prod Firm</td>
            </tr>
          </table>
          
        </section>
  )
};

export default LicensingInformationRyder;
