import React from 'react';

import Layout from 'pages/_common/Layout';
import TermsOutbound from 'pages/TermsOutbound';
import TermsRyder from 'pages/TermsRyder';

import style from 'styles/pages/Terms.module.scss';

const Terms = () => {

  const terms = {
    'mack': {
      terms: <TermsOutbound />,
    },
    'volvo': {
      terms: <TermsOutbound />,
    },
    'trux': {
      terms: <TermsOutbound />,
    },
    'ryder': {
      terms: <TermsRyder />,
    },
  }

  return (
    <Layout wClass={style.layout}>
      <div className={style.page}>
        {terms[process.env.THEME].terms}
      </div>
    </Layout>
  )
};

export default Terms;
