// ESLINT TODO rules to be fixed
/* eslint-disable arrow-body-style */
import React from 'react';
import { Route, Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history';

import GetStarted from 'pages/GetStarted';
import Dealer from 'pages/Dealer';
//import OnboardingWizard from 'pages/Wizard/OnboardingWizard';
import UserInformationForm from 'pages/UserInformationForm';
import Thanks from 'pages/Thanks';
import ErrorPage from 'pages/ErrorPage';
import Terms from 'pages/Terms';
import Privacy from 'pages/Privacy';
import LicensingInformation from 'pages/LicensingInformation';

const history = createBrowserHistory();

const Routes = () => {
  return (
    <Router history={history}>
        <Switch>
          <Route exact path="/" component={UserInformationForm} />
          <Route exact path="/start/:id" component={GetStarted} />
          <Route exact path="/dealer/:id?" component={Dealer} />
          {/*<Route exact path="/wizard/:id?/:view?" component={OnboardingWizard} />*/}
          <Route exact path="/wizard/:id?/:view?" component={UserInformationForm} />
          <Route exact path="/thanks" component={Thanks} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/licensing" component={LicensingInformation} />
          <Route path="*" component={ErrorPage} />
        </Switch>
    </Router>
  );
};

export default Routes;
