import React from 'react';
import Layout from 'pages/_common/Layout';
import style from 'styles/pages/ErrorPage.module.scss';

const ErrorPage = ({title="Error", message="Page Not Found"}) => {  
  return (
    <Layout wClass={style.layout}>
      <div className={style.page}>
        <h1>{title}</h1>
        <p>{message}</p>
      </div>
    </Layout>
  )
};

export default ErrorPage;
