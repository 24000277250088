import React from 'react';
import { themeLinks } from 'utils/links';

const LicensingInformationOutbound = () => {

  const licensing = themeLinks[process.env.THEME].licensing;

  return (
    <section>
      <h1>Licensing Information</h1>
      <p><a href={licensing} target="_blank" rel="noreferrer">Click here</a></p>
    </section>
  )
};

export default LicensingInformationOutbound;
