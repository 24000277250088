import React from 'react';

import Layout from 'pages/_common/Layout';

import style from 'styles/pages/Maintenance.module.scss';

const Maintenance = () => (
  <Layout wClass={style.layout}>
    <div className={style.page}>
      <h1>Maintenance in progress</h1>
      <p className={style.sub}>We should be back online soon.</p>
    </div>
  </Layout>
);

export default Maintenance;
