import React from 'react';
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';

import { states } from 'utils/states';
import { useForm } from "react-hook-form";

import SelectInput from 'rein-core-components/Forms/SelectInput';
import RadioText from 'rein-core-components/Forms/RadioText';
import PrimaryButton from 'rein-core-components/Buttons/PrimaryButton';
import Layout from 'pages/_common/Layout';

import * as Validation from 'utils/validation';

import style from 'styles/pages/GetStarted.module.scss';


const GetStarted = () => {  
  const history = useHistory();
  let { id } = useParams();

  const methods = useForm({
    mode: 'onChange',
  });

  const { handleSubmit, control, errors, register, watch } = methods;
  const watchState = watch("state");
  const watchDot = watch("dot", false);
  const watchCdl = watch("cdl", false);
  const watchUnits = watch("units", false);

  const stateList = ['CA', 'NC', 'OH', 'WI'];
  const isNLState = stateList.includes(watchState && watchState.value);
      
  const onSubmit = data => {
    //console.log(data);

    // Determine destination
    if (isNLState && watchDot === 'false' && watchCdl === 'true' && watchUnits === 'true') {
      // Send user to MVP
      window.location.href = `${process.env.REACT_APP_MVP_SITE}`;
    } else {
      // Continue to VIO Wizard flow
      history.push(`/wizard/${id}`);
    }

  }

  return (
    <Layout wClass={style.layout}>
      <form onSubmit={handleSubmit(onSubmit)} className={style.page}>
        <section>
          <h1 className={style.title}>Let's Get Started</h1>

          <div className={`RadioFieldLbl ${style.state_lbl}`}>
            <p>Select your garaging state</p>
          </div>
          <div className={style.state}>
            <SelectInput 
              control={control}
              errors={errors}
              name="state"
              label="State"
              placeholder="Select"
              options={states}
              validate={[Validation.required]} 
            />
          </div>

          <div className={`RadioFieldLbl ${style.dot_lbl}`}>
            <p>Do you operate or are planning to operate under your own DOT number?</p>
          </div>
          <div className={`RadioField ${style.dot}`}>
            <RadioText 
              name="dot"
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]} 
              register={register} 
              errors={errors} 
              validate={[Validation.required]}
            />
          </div>

          {isNLState && watchDot === 'false' && (
            <>
            <div className={`RadioFieldLbl ${style.cdl_lbl}`}>
              <p>Do any of the units you need to insure require a CDL to operate?</p>
            </div>
            <div className={`RadioField ${style.cdl}`}>
              <RadioText 
                name="cdl"
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]} 
                register={register} 
                errors={errors} 
                validate={[Validation.required]}
              />
            </div>

            <div className={`RadioFieldLbl ${style.units_lbl}`}>
              <p>Do you have less than 3 units to insure?</p>
            </div>
            <div className={`RadioField ${style.units}`}>
              <RadioText 
                name="units"
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]} 
                register={register} 
                errors={errors} 
                validate={[Validation.required]}
              />
            </div>
            </>
          )}


          <div className={style.controls}>
            <div className={style.next}>
              <PrimaryButton icon2="m navigate_next" type="submit">Next</PrimaryButton>
            </div>
          </div>
        </section>
      </form>
    </Layout>
  )
};

export default GetStarted;
