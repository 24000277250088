import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router";

import { useForm } from "react-hook-form";
import LoadingSpinner from 'components/LoadingSpinner';
import ErrorPage from 'pages/ErrorPage';

import InputText2 from 'rein-core-components/Forms/InputText2';
import PrimaryButton from 'rein-core-components/Buttons/PrimaryButton';
import Layout from 'pages/_common/Layout';

import * as Validation from 'utils/validation';

import style from 'styles/pages/Dealer.module.scss';


const Dealer = () => {  
  let { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetch(`https://iopcscpr6f.execute-api.us-east-1.amazonaws.com/prod/dealers/${id}`)
      .then(response => {
        if (!response.ok) {
          throw Error('Something went wrong');
        }
        return response.json();;
      })
      .then(data => {
          console.log(data)

          setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false)
        setError(error.message)
      });
  }
  // eslint-disable-next-line
  }, []);

  const methods = useForm({
    mode: 'onChange',
  });

  const { handleSubmit, errors, register } = methods;

  const [searchResults, setSearchResults] = useState(null);

  const clients = [
    {
      "id": "04d5dbfd-1922-4daa-b45e-0d8099a84331",
      "owner_name": "Name 1a",
      "business_name": "George Boehringer"
    },
    {
      "id": "1234",
      "owner_name": "Name 1a",
      "business_name": "123 business"
    },
    {
      "id": "123",
      "owner_name": "Client 2",
      "business_name": "business name 2"
    },
  ];
     
  const onSubmit = data => {
    const results = clients.filter(el => el.owner_name === data.client_search || el.business_name === data.client_search);
    setSearchResults(results);
  }

  const renderSearchResults = () => {
    if (searchResults && searchResults.length > 0) {

      const clients = searchResults.map((client) => (
        <Link to={`wizard/${client.id}`}>{client.owner_name} - {client.business_name}</Link>
      ))

      return (
        <div className={style.searchResults}>
          <h3>Clients Found:</h3>
          <div className={style.clients}>
            {clients}
          </div>
        </div>
      )
    }
    return <div className={style.noResults}>No clients found</div>;
  }

  if (isLoading) return <LoadingSpinner />;
  if (error) return <ErrorPage message={error} />;

  return (
    <Layout wClass={style.layout}>
      <form onSubmit={handleSubmit(onSubmit)} className={style.page}>
        <section>
          <h1 className={style.title}>Dealer</h1>
          <h2 className={style.subtitle}>Search for client</h2>

          <div className={style.search}>
            <div className={style.search_field}>
              <InputText2
                id="client_search"
                name="client_search"
                placeholder="Search" 
                register={register} 
                errors={errors} 
                validate={[Validation.required]} 
              />
            </div>
            <div className={style.search_btn}>
                <PrimaryButton icon2="m navigate_next" type="submit">Search</PrimaryButton>
            </div>
          </div>

          {searchResults && renderSearchResults()}
          
        {/*<pre>{JSON.stringify(clients, null, 2) }</pre>*/}
        </section>
      </form>
    </Layout>
  )
};

export default Dealer;
