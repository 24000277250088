import moment from 'moment';
// import { stringToNumber } from './currencyUtils';

export const required = (value) => {
  if (typeof value !== 'undefined' && value !== '') return undefined;
  return 'Required';
};

export const requiredArray = (value) => {
  if (value && typeof value !== 'undefined' && value.length !== 0) return undefined;
  return 'Required';
};

export const name = (value) => {
  if (value && !/^([A-Za-z]([A-Za-z '-])*[A-Za-z])+$/.test(value.trim())) return 'name';
  return undefined;
};

export const number = (value) => {
  if (value && isNaN(value)) return 'Must be a number';
  return undefined;
};

export const yearRange = (value) => {
  const nextYear = moment().year() + 1;
  if (value >= 1990 && value <= nextYear) return undefined;
  return `Must be between 1990 and ${nextYear}`;
};

export const email = (value) => {
  if (value && !/^[A-Z0-9._+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i.test(value)) return 'Invalid email';
  return undefined;
};

export const phoneNumber = (value) => {
  if (value && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)) return 'Invalid phone number';
  return undefined;
};

export const dateWithin30Days = (value) => {
  const isValid = moment(value, 'YYYY/MM/DD').isBetween(moment(), moment().add(30, 'days'), 'days', '[]');

  if (!isValid) return 'Date should be within 30 days';
  return undefined;
};

export const over25 = (value) => {
  const olderThan25 = moment(value, 'YYYY/MM/DD').isSameOrBefore(moment().subtract(25, 'years'));
  if (!olderThan25) return 'Must be at least 25 years old';
  return undefined;
};

export const dateFormat = (value) => {
  const parsedDate = moment(value, 'YYYY/MM/DD');
  if (!parsedDate.isValid() || value.length < 8) return 'Date must be YYYY/MM/DD format';

  return undefined;
};

export const password = (value) => {
  const passwordIsOk = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$/.test(value);
  if (!passwordIsOk) return 'Passwords must contain at least 8 characters, one upper case letter, one lowercase letter, one special character, and one number.';
  return undefined;
};

export const vinNumber = (value) => {
  if (value && !/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/i.test(value)) return 'Invalid VIN number';
  return undefined;
};

export const alphaNumeric = (value) => {
  if (value && /[^a-zA-Z0-9 ]/i.test(value)) return 'No special characters';
  return undefined;
};

export const maxLength = (max) => (value) => {
  if (!value || value.length <= max) return undefined;
  return `Maximum ${max} characters`;
};

export const greaterThanZero = (value) => {
  if (Number(value) <= 0) return 'Must be greater than 0';
  return undefined;
};

export const lessThan = (amount) => (value) => {
  if (value && Number(value) >= amount) return `Must be less than ${amount}`;
  return undefined;
};

export const wholeNumber = (value) => {
  if (value && Number.isInteger(value)) return undefined;
  return 'Must be a whole number';
};

/*
export const validVehicleValue = (value) => {
  const parsedNumber = stringToNumber(value);
  const isWholeNumber = wholeNumber(parsedNumber);
  const isPostiveNumber = greaterThanZero(parsedNumber);
  const isLessThanMillion = lessThan(999999)(parsedNumber);
  return isWholeNumber || isPostiveNumber || isLessThanMillion;
};
*/

export const compareConfirmPassword = (password) => (comparePassword) => {
  if (comparePassword && comparePassword !== password) {
    return 'Confirm password must be same with password';
  }
  return undefined;
};

export const mapValidationsToObject = (validations) => validations.reduce((validationObject, validation) => {
  validationObject[validation.name] = validation;
  return validationObject;
}, {});
