import React from 'react';
import PropTypes from 'prop-types';
import style from 'styles/components/Header.module.scss';

const Header = ({ wClass }) => (
  <div className={`${style.header} ${wClass} Header`}>
    <div>
      <div className={style.label}>
        <div className={style.title}>&nbsp;</div>
      </div>
      <div className={style.brand}>
        <div className={style.logo}>&nbsp;</div>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  wClass: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
};

export default Header;
