import React from 'react';

import Layout from 'pages/_common/Layout';
import PrivacyOutbound from 'pages/PrivacyOutbound';
import PrivacyRyder from 'pages/PrivacyRyder';

import style from 'styles/pages/Terms.module.scss';

const Privacy = () => {

  const privacy = {
    'mack': {
      privacy: <PrivacyOutbound />,
    },
    'volvo': {
      privacy: <PrivacyOutbound />,
    },
    'trux': {
      privacy: <PrivacyOutbound />,
    },
    'ryder': {
      privacy: <PrivacyRyder />,
    },
  }

  return (
    <Layout wClass={style.layout}>
      <div className={style.page}>
        {privacy[process.env.THEME].privacy}
      </div>
    </Layout>
  )
};

export default Privacy;
