import React from 'react';
import { Link, Element } from 'react-scroll';

const PrivacyRyder = () => {


  return (
        <section>
          <h1>Privacy Policy</h1>
          <p>Effective Date: November 1, 2021<br />
          Last Updated: November 1, 2021</p>
          
          <p>Your privacy is important. At REIN, we work hard to protect your Personal Information. This Privacy Policy explains what information REIN collects and how we protect and might share it. Whether you are a  current or a former customer, this policy outlines the choices available to you to manage your privacy.</p>

<ul>
<li><Link activeClass="active" to="scope_of_privacy" spy={true} smooth={true} offset={-75} duration={500}>Scope of Privacy Policy</Link></li>
<li><Link activeClass="active" to="information_we_collect" spy={true} smooth={true} offset={-75} duration={500}>What Information We Collect and How We Collect It</Link></li>
<li><Link activeClass="active" to="how_we_protect" spy={true} smooth={true} offset={-75} duration={500}>How We Protect and Use Personal Information</Link></li>
<li><Link activeClass="active" to="children" spy={true} smooth={true} offset={-75} duration={500}>Children’s Online Privacy</Link></li>
<li><Link activeClass="active" to="disclosure_to_comply" spy={true} smooth={true} offset={-75} duration={500}>Disclosure to Comply with Law</Link></li>
<li><Link activeClass="active" to="manage_your_privacy" spy={true} smooth={true} offset={-75} duration={500}>How You can Manage Your Privacy</Link></li>
<li><Link activeClass="active" to="california" spy={true} smooth={true} offset={-75} duration={500}>Notice to California Residents</Link></li>
<li><Link activeClass="active" to="outsite_usa" spy={true} smooth={true} offset={-75} duration={500}>Notice to Residents of Countries Outside the United States</Link></li>
<li><Link activeClass="active" to="contact" spy={true} smooth={true} offset={-75} duration={500}>Contact Information</Link></li>
</ul>

<h2><Element name="scope_of_privacy">SCOPE OF PRIVACY POLICY</Element></h2>
<p>When we refer hereinafter to “we”, “our”, “us” or “REIN”, we mean REIN Technologies (US) Inc. and its subsidiaries, including REIN Connected Insurance Agency, LLC. When we refer hereinafter to “User”, “you” or “your”, we mean you or the person visiting our websites and/or using our products and services. Our “Services” mean (a) products and services we sell online, and (b) website information or tools we provide on REIN.ai, ryder.reinconnectedinsurance.com, or any other website linking to this Privacy Policy and any mobile applications we develop, whether independently or in collaboration with third parties, for the web, Android or iOS (collectively, our “Sites”).</p>
<p>This Privacy Policy explains how we collect personal information of Users of our Sites and our Services, how we handle and protect that information, and what Users can do to help protect that information.   When we refer to “Personal Information”, we mean data that can be used to uniquely identify or contact a single person. </p>
<p>Please note that we may change this Privacy Policy from time to time to accurately reflect how we then gather and manage User information. All changes to this Privacy Policy are effective upon posting to our Sites, as shown by the Last Updated Date above, without prior notice.</p>
<p>Please read this Privacy Policy for full details. If you do not agree to the terms of this Privacy Policy, then we cannot provide our Services to you, and you should immediately stop accessing our Sites and Services and deactivate any account with us which you have created.</p>
<p>By accessing or using our Sites and Services, you agree to the collection and use of information in accordance with this Privacy Policy. Your continued use of our Sites and Services after we post any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your agreement to abide and be bound by the modified Privacy Policy.</p>
<h2><Element name="information_we_collect" className="element">WHAT INFORMATION WE COLLECT AND HOW WE COLLECT IT</Element></h2>
<p>In the normal course of operating our Sites and providing our Services, we may collect, store and process Personal Information that you make available to us when accessing or using our Sites and Services.  We may collect this information directly from you, via our service providers, government agencies, consumer reporting agencies, insurers, public records and/or other third-party sources as described in this section. </p>
<ul>
<li>Registration and Account Information: When you register with us, create an account and access our Services, or thereafter update your information on your account, you may voluntarily provide us with Personal Information. When you create an account, we will ask for the following Personal Information from you: your name, email address, physical address, date of birth, license number, phone number, professional or employment information and account password.  </li>
<li>Account Management: Following registration, you may add, delete or modify information on your account, including information concerning drivers and vehicles, for example. If you have questions about reviewing your Personal Information or making changes to it, please contact us as provided below. Driver information may include a driver’s name, date of birth, and license number. Vehicle information may include details such as the VIN (vehicle identification number).</li>
<li>Job Application: If you visit our “Careers” page and choose to apply for one of the positions listed, we will ask you to provide your first and last name, email address, and upload a resume.  You also have the option to upload a cover letter.  Categories of information we collect will depend on what you include in your resume, such as employment and education history, language skills, qualifications, and other information you choose to share with us.  </li>
<li>Access or Use Our Services: When you access our Services, Personal Information that you provide to secure insurance or to pay insurance premiums is provided to us voluntarily. We may transmit your Personal Information to our insurance carriers, insurance program and enterprise partners, or their representatives for purposes related to this insurance program, your insurance policy or claims.  If you purchase insurance through our Sites, we may transmit a Certificate of Insurance containing Personal Information to any loss payees, additional insureds or to the dealership where you purchased or financed your vehicle(s).  </li>
<li>Communications with Us: You may voluntarily make Personal Information available to us when you contact our customer service agents, and/or when you report, change or update an insurance claim. You may also voluntarily provide us with Personal Information so we can respond to your requests for information, manage the application process, and/or send you communications. As provided below, you may opt-out of receiving communications from us at any time.</li>
<li>Survey Information: From time to time, you may complete surveys relating to your experiences with our Services, and may in turn provide to us your demographic information (e.g., city and state) and other Personal Information. </li>
<li>Log Data: We may also receive, store and process “log data”, which is information that is automatically recorded whenever you access or use our Sites and Services, regardless of whether you are registered with us or logged into your account, such as your IP address, location data provided by your browser’s Geolocation API (such as an approximate latitude and longitude based on your IP address), the date and time you accessed our Sites, the hardware and software you are using, referring and exit pages and URLs, the number of clicks, device event information, pages viewed and the order of those pages, and the amount of time spent on particular pages.  See the “Cookies” section below for more information about how we collect log data.</li>
<li>Vehicle, Device and Driver Data:  As part of our data analysis services  and claims services provided to insurance and enterprise partners and customers, we may, on behalf of our partners, receive, store and process data, including but not limited to driver data, from vehicles such as commercial trucks, vehicle devices, and software used in such vehicles.  This data may include telematics data, GPS data, diagnostics data and the gender, birthdate and state of vehicle drivers.  We analyze this data on behalf of our insurance and enterprise partners to assess risk, enhance loss prevention tools and improve the overall insurance process.  We may also transmit this data to our insurance carriers or their representatives as part of the claims process.</li>
<li>Photos: We may receive and store photos that you upload to our Sites as part of the claims process. We may also transmit these photos to our insurance carriers or their representatives as part of the claims process.</li>
<li>Telephone Communication: When you call our customer service team, we may collect your name, account ID and other information to assist you, in addition to any Personal Information that you may provide in the process of obtaining or learning about our Services.</li>
<li>Information from Other Sources or Combined by Us: We may combine information from outside sources to customize your experience using our Services.  For example: we use certain service providers to capture and aggregate User data as well as to communicate with our Users.  We utilize data feeds from our insurance program and enterprise partners to aggregate User data, including but not limited to Personal Information and vehicle, device, and driver data, and improve your experience with our Services. We also use third-party analytic services to help us better understand usage of our Services. In particular, we provide a limited amount of User information (such as a User’s email address and sign-up date) to such third-party services and utilize such services to collect data for analysis when Users visit our Sites or use our Services so that we may better improve our Sites and Services.</li>
</ul>
<p>We may also obtain other Personal Information about you as necessary from consumer reporting agencies, insurers and unaffiliated third parties, public records and other sources. Information collected about you may include information related to your employment history, credit score, credit history, or transactions with us or others. Also, we may check the information you provide to us with certain government databases, including the Office of Foreign Assets Control (OFAC) and state motor vehicle departments, regarding your application to use our Services.</p>
<ul>
<li>Social Media Services: When you sign in, or engage, with our Services through Social Media Websites, you are agreeing to give us on-going access to your Personal Information that you have provided to such third-party accounts, including your public profile, birthday, work history, education history, personal interests and other personal information. We are not responsible for the privacy practices of Social Media Websites and third-party accounts that are linked from our Sites or Services. We encourage you to check the privacy policies of those Social Media Websites to understand and exercise your options to limit access to your information.</li>
<li>Payment Processing Services:  We use third-party payment processing services for all payment transactions. Privacy policies and security policies for these third-party services are available on their respective sites, and they are responsible for maintaining any necessary compliance with applicable data security laws or industry standards. These third-party services do not share the transactional data, including your credit card or debit card number, and we do not keep any record of this information.</li>
</ul>
<h3>COOKIES</h3>
<p>In addition, our Sites automatically collect log data about Users. For example, our Sites automatically gather a User’s IP address, location provided by their browser’s Geolocation API (such as an approximate latitude and longitude based on the User’s IP address), which Internet Service Provider the User utilizes, statistics about how Users navigate through and use our Sites, and other log data gathered through the use of “cookies.” A “cookie” is a piece of data stored on the User’s device tied to information about the User. Web browsers typically allow you to control most cookies through browser settings. You may decline our cookies if your browser permits, but it may impact your ability to fully use some of the features on our Sites. By using our Sites, you agree that we can place cookies on your browser, unless you have limited their use in your browser settings.</p>
<p>We use log data obtained via cookies to maintain the quality of our Sites and Services, help us better understand how Users choose our Sites, present information on our Sites in compliance with applicable state insurance rules and regulations, and to know when Users have accessed our Sites from a paid advertiser, another one of our Sites, or a strategic partner website. Location data provided by your browser’s Geolocation API may be used to deliver a viewing experience on our Sites tailored for the User’s area.  We also compile statistics that track the usage of our Sites. These statistics will be used for internal purposes .and may also be shared with our insurance program and enterprise partners to help us better understand how to best provide and expand our Services. </p>
<p>Solely for the purposes of confirming valid traffic from certain pay-per-click search engines, IP addresses for such Users are monitored, and may be compared to the records of that search engine.</p>
<h3>DO-NOT TRACK; THIRD-PARTY TRACKING</h3>
<p>Certain Internet browsers such as Explorer, Firefox and Safari offer a “do not track” or “DNT” feature that sends a signal to websites visited by the user indicating the user does not wish to be tracked. We do not currently respond to browsers’ DNT signals with respect to our Sites, in part, because no common industry standard for DNT has been adopted by industry groups, technology companies or regulators, including no consistent standard of interpreting user intent. We will continue to monitor developments around DNT browser technology and the implementation of a common standard.</p>
<p>Other third parties may deliver cookies to your device in order to track your online behaviors over time and across nonaffiliated websites or to deliver targeted advertisements either on our Sites or on other websites. You have choices about the information third parties may collect about you through our Sites. For example, if you do not want your information sent to a third party, you may download an Opt-out Browser Add-on by <a href="https://tools.google.com/dlpage/gaoptout?hl=en">clicking here</a>.</p>
<h3>THIRD PARTY WEBSITES</h3>
<p>We may maintain links to other websites on our Sites, including social media sites. If a User chooses to visit other websites from our Sites, we are not responsible for the privacy practices or content of those other websites. We encourage you to refer to such third party’s privacy policy to find out more about such party’s data practices.</p>
<h2><Element name="how_we_protect" className="element">HOW WE PROTECT AND USE PERSONAL INFORMATION</Element></h2>
<h3>SECURITY</h3>
<p>We have commercially reasonable security measures in place within our database and physical facilities to protect against the loss, misuse, or alteration of information that has been collected on our Sites. However, please be aware that no method of transmission over the Internet or method of electronic storage is 100% secure, and we are unable to guarantee the absolute security of the Personal Information.  </p>
<h3>INFORMATION USE & SERVICE PROVIDERS</h3>
<p>We use your Personal Information to provide market-leading services to you, to confirm that the information we know about you is accurate and complete, to communicate with you, to respond to your questions or requests, to provide our Sites and Services to you, to report claims, to prevent fraud, to conduct analyses for our business, for security purposes, for other normal business administration and related services, to protect REIN and to conduct investigations of possible breaches of law, to cooperate with law enforcement, or to identify, contact or bring legal action against someone who may be violating any agreement with us, and as otherwise required or permitted by applicable law.  As mentioned above, we use log data to maintain the quality of our Sites and Services, help us better understand how Users choose our Sites, and to know when Users have accessed our Site from other sites.  If you apply for a job using one of our Sites, then we will use that information to process your application.  </p>
<p>We may employ service providers (companies and individuals) to perform functions on our behalf. Examples may include providing marketing assistance, performing statistical analysis, providing customer service, payment processing and maintenance of sanctions lists. These service providers will have access to the Personal Information only as necessary to perform their functions and may not access or use it for any other purpose.</p>
<p>Your password and account credentials are sensitive. We will assume that anyone using your account as you, by using your password and credentials, is you. We will not ask you for your password except on our log-in screen. Please do not share your password or account credentials with anyone or allow anyone else to use them. By creating an account on our Sites, you agree that the information you share with us is complete and accurate and that you will keep your information up-to-date.</p>
<p>We may utilize e-mail to communicate with Users regarding our Services. We may also occasionally use a User’s Personal Information to notify the User of our Services and special offers via periodic mailings or e-mail updates in accordance with the User’s preferences. Users may opt-out of these communications by emailing us at info@rein.ai with “UNSUBSCRIBE” in the subject line.</p>
<p>We will not sell Personal Information of any User. However, in the event of an ongoing or proposed business transition, such as a merger or an acquisition of our equity interests or assets, the Personal Information of our Users will, in most instances, be part of the assets transferred or shared. If so, the successor organization’s use of your Personal Information will remain subject to this Privacy Policy and the privacy preferences you have expressed to us.</p>
<p>We may share certain Personal Information with our service providers in connection with our Services. We may also share non-Personal Information in order to improve our risk modeling and perform aggregate reporting. </p>
<h2><Element name="children">CHILDREN’S ONLINE PRIVACY</Element></h2>
<p>Our Services are not directed toward children. It is our policy not to solicit or collect personally identifiable information from children under age 13 (“Child”) without parent or guardian consent. If you are a parent or guardian and you learn that your Child has provided us with Personal Information, please contact us. If we discover that a Child under 13 has provided us with Personal Information, we will take steps to delete such information from our servers as soon as possible.</p>
<h2><Element name="disclosure_to_comply" className="element">DISCLOSURE TO COMPLY WITH LAW</Element></h2>
<p>We may need to disclose or provide access to Personal Information if required to do so by law or if we have a good faith belief that such action is necessary to comply with the law, such as in connection with a judicial proceeding, court order or legal process, including (a) to respond to claims asserted against us; (b) to comply with legal process (e.g., subpoenas and warrants); (c) to enforce and administer our agreements with Users; (d) to prevent or investigate fraud; or (e) to protect our rights or the safety of us, our Users or members of the public.</p>
<h2><Element name="manage_your_privacy">HOW YOU CAN MANAGE YOUR PRIVACY</Element></h2>
<p>Unsubscribe: Users may opt-out of these communications by emailing us at info@rein.ai with “UNSUBSCRIBE” in the subject line.  Your option not to receive promotional and marketing material will not preclude us from corresponding with you, by email or otherwise, regarding your existing or past business relationships with us, and will not preclude us from accessing and viewing your Personal Information in the course of maintaining and improving our Sites and our Services.</p>
<p>Account Management: Following registration, you may add, delete or modify information on your account, including information concerning drivers and vehicles, for example. If you have questions about reviewing your Personal Information or making changes to it, please contact us at info@rein.ai.  We may also ask you to verify your identity before granting access or making corrections.  </p>
<p>Cookies:  To learn how you may be able to reduce the number of cookies you receive from us, or delete cookies that have already been installed in your browser’s cookie folder, please refer to your browser’s help menu or other instructions related to your browser.  If you do disable or opt out of receiving cookies, please be aware that some features and services on our Site may not work as intended and you may not have access to all of the Services and features we may offer.</p>
<h2><Element name="california">NOTICE TO CALIFORNIA RESIDENTS</Element></h2>
<p>For details on the personal information (as defined by the California Consumer Privacy Act of 2018 or the “CCPA”) we collected about California residents in the last 12 months, the sources of that information, our business or commercial purposes for collecting that information, and the third parties with whom we shared that information, please refer to the corresponding sections of this Privacy Policy as follows:</p>
<ul>
<li>
  <Link activeClass="active" to="information_we_collect" spy={true} smooth={true} offset={-75} duration={500} >
    WHAT INFORMATION WE COLLECT AND HOW WE COLLECT IT
  </Link> – Categories of personal information we collected  </li>
<li>
  <Link activeClass="active" to="information_we_collect" spy={true} smooth={true} offset={-75} duration={500} >
    WHAT INFORMATION WE COLLECT AND HOW WE COLLECT IT
  </Link> – Sources from which personal information was collected </li>
<li>
  <Link activeClass="active" to="how_we_protect" spy={true} smooth={true} offset={-75} duration={500} >
    HOW WE PROTECT AND USE INFORMATION, INFORMATION USE AND SERVICE PROVIDERS
  </Link> – Our business or commercial purposes for collecting personal information</li>
<li>
  <Link activeClass="active" to="disclosure_to_comply" spy={true} smooth={true} offset={-75} duration={500} >
    DISCLOSURE TO COMPLY WITH LAW
  </Link> – Third parties with whom we shared personal information</li>
</ul>
<p>your ccpa rights and how to exercise them</p>
<p>If you are a California resident, you have certain rights, pursuant to CCPA. These CCPA rights may only apply in certain circumstances and are subject to certain exemptions.  Please see the information below for a summary of your rights and how to exercise your rights. Please note that we may ask for certain information to verify the request in accordance with applicable law.   </p>
<ul>
<li>Right to Know: You have the right to request that we disclose what personal information we collect, use, disclose and/or sell about you. To exercise your right to know, please email us. </li>
<li>Right to Delete: You have the right to delete personal information that we collect or maintain about you. To request deletion of your information, please email us at data@rein.ai. You will be required to provide certain information which we will use to verify your request. Please note that certain exceptions apply to this right, such as when we must retain your information to comply with law. We will notify you of any such exceptions as applicable to your request. </li>
<li>Right to Non-Discrimination: You have the right not to receive discriminatory treatment by us for the exercise of any of your CCPA rights. However, we may offer certain financial incentives, charge reasonable fees related to your requests, or deny your right to know or right to request deletion in accordance with applicable law.</li>
</ul>
<p>You can exercise your CCPA rights yourself or you can designate an authorized agent to make a request on your behalf. Your authorized agent must be able to demonstrate authority to act on your behalf as further instructed when submitting a verifiable request.  We may require that you provide the authorized agent written permission to submit a request on your behalf and that the authorized agent verifies their own identity directly with us.  </p>
<p>We did not sell any personal information to third-parties for their business or commercial purposes during the last 12 months.  We do not knowingly collect any personal information from anyone under 16 years of age.  We do not sell personal information of anyone under 16 years of age.  </p>
<p>If you would like to print a copy of this Privacy Policy, please select the “Print” button in your web browser.</p>
<p>This Notice to California Residents was last updated on November 1, 2021.</p>
<p>If you have questions about this Privacy Policy, including anything in this Section, please contact us at info@rein.ai.  </p>
<h2><Element name="outsite_usa">NOTICE TO RESIDENTS OF COUNTRIES OUTSIDE THE UNITED STATES</Element></h2>
<p>We are based in the United States. Personal information may be accessed by us or transferred to us in the United States or to our affiliates, business partners, or service providers elsewhere in the world.  If you are located outside of the United States, be advised that any information you provide to us will be transferred to and stored in the United States and that, by submitting information to us, you explicitly authorize its transfer and storage within the United States.  We will protect the privacy and security of personal information according to this Privacy Policy regardless of where it is processed or stored.</p>
<h2><Element name="contact">CONTACT INFORMATION</Element></h2>
<p>If you have any questions or suggestions regarding this Privacy Policy or how to exercise any of your rights in this Privacy Policy, please contact us by email at info@rein.ai.</p>


<p>Insurance services provided by REIN<br />
© REIN Technologies (US) Inc. 2021 </p>

        </section>
  )
};

export default PrivacyRyder;
