import React from 'react';
import { themeLinks } from 'utils/links';

const PrivacyOutbound = () => {

  const privacy = themeLinks[process.env.THEME].privacy;

  return (
        <section>
          <h1>Privacy Policy</h1>
          <p><a href={privacy} target="_blank" rel="noreferrer">Click here</a></p>
        </section>
  )
};

export default PrivacyOutbound;
