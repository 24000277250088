import React from 'react';
import Header from 'components/Header/Header';
import Help from 'components/Help';

import style from 'styles/pages/_common/Layout.module.scss';

function Layout({ wClass, children, ...rest }) {

  return (
    <div className={`${style.layout} ${wClass} Layout`}>
      <div className={style.header}>
        <Header />
      </div>
      <section className={style.page}>
        {children}
      </section>

      <Help />
    </div>
  );
}

export default Layout;
