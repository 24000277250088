import React from 'react';
import style from 'styles/components/LoadingSpinner.module.scss';

const LoadingSpinner = () => (
  <div className={`spinner ${style.spinnerBox}`}>
    <div>
      <div className={`${style.dblSpinner}`} />
      <div className={`${style.dblSpinner} ${style.dblSpinner2}`} />
    </div>
  </div>
);

export default LoadingSpinner;
