import React, { useState } from 'react';
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { themeLinks } from 'utils/links';
// import sgMail from 'sendgrid/mail'

import Layout from 'pages/_common/Layout';
import LoadingSpinner from 'components/LoadingSpinner';

import InputText2 from 'rein-core-components/Forms/InputText2';
import InputNumberFormat from 'rein-core-components/Forms/InputNumberFormat';
import Checkbox from 'rein-core-components/Forms/Checkbox';
import Tooltip from 'rein-core-components/Misc/Tooltip';
import PrimaryButton from 'rein-core-components/Buttons/PrimaryButton';

import style from 'styles/pages/UserInformationForm.module.scss';

import * as Validation from 'utils/validation';

const UserInformationForm = () => {
  const history = useHistory();
  let { view } = useParams();  
  const [isLoading, setIsLoading] = useState(false);
  const sgMail = require('@sendgrid/mail')
  sgMail.setApiKey('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')
  console.log(process.env.SENDGRID_API_KEY)
  const { 
    handleSubmit, 
    register, 
    errors, 
    control
   } = useForm();

   const postData = (data) => {
    console.log(data)
    fetch('https://prod-173.westus.logic.azure.com:443/workflows/b56f57e49e63489280d8806f594284da/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=qGWYL7cetAVpSnOnxDMutFL8EqHRPYmnFtQYUDHQG9s', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then(response => {
        console.log('response:', response)
      })
      .then(data => {
        console.log('data', data)
        setIsLoading(false);
        history.push('/thanks');
      });
    // fetch('https://script.google.com/macros/s/AKfycbz8cq9wFNF3X0Y_998sIGZs0zBjzK5X79_3hoa0SigGzJ5M56wN-G6-ia3Ut-fK1oHa/exec', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'text/plain;charset=utf-8' },
    //   body: JSON.stringify(data)
    // })
    //   .then(response => {
    //     console.log('response:', response)
    //   })
    //   .then(data => {
    //     console.log('data', data)
    //     setIsLoading(false);
    //     history.push('/thanks');
    //   });
    // const msg = {
    //   to: 'ppinto@rein.ai', // Change to your recipient
    //   from: 'ryder@droneinsurance.com', // Change to your verified sender
    //   subject: 'Sending with SendGrid is Fun',
    //   text: 'and easy to do anywhere, even with Node.js',
    //   html: '<strong>and easy to do anywhere, even with Node.js</strong>',
    // }
    // sgMail
    //   .send(msg)
    //   .then(() => {
    //     console.log('Email sent')
    //     setIsLoading(false);
    //     history.push('/thanks');
    //   })
    //   .catch((error) => {
    //     console.error(error)
    //   })
  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  const onSubmit = data => {
    console.log(data)
    data.partner = capitalize(process.env.THEME)
    data.partner_source = capitalize(process.env.THEME)
    postData(data);
  }

  const readOnly = (view === "csr") ? true : false;

  const privacy = themeLinks[process.env.THEME].privacy;
  const tos = themeLinks[process.env.THEME].tos;
  const licensing = themeLinks[process.env.THEME].licensing;

  const termsToolTip = (
    <Tooltip 
      width={250} 
      position="rightTop" 
      mobilePosition="bottomFull"
    >
      We and our insurance partners use information from you and other sources, such as Motor Vehicle Reports, for rating/underwriting purposes. We may review a credit report or use a credit-based insurance score based on the information contained in that credit report, but such information will not be used for any purpose other than the underwriting of the insurance policy for which you have applied. Routine inquires may also be made utilizing information from other sources. Review our Privacy Policy to learn more.  
    </Tooltip>
  );


  if (isLoading) return <LoadingSpinner />;

  return (
    <Layout wClass={style.layout}>
    <form onSubmit={handleSubmit(onSubmit)} className={style.page}>
      <section>
        <h1>Applicant</h1>

        <div className={style.owner_name}>
          <InputText2
            id="owner_name"
            name="ownersName"
            placeholder="Owner Name" 
            register={register} 
            errors={errors} 
            validate={[Validation.required]} 
            readOnly={readOnly}
          />
        </div>

        <div className={style.business_name}>
          <InputText2
            id="business_name"
            name="businessName"
            placeholder="Business Name or DBA" 
            register={register} 
            //errors={errors} 
            //validate={[Validation.required]} 
            readOnly={readOnly}
          />
        </div>

        <div className={style.phone}>
          <InputNumberFormat
            control={control}
            id="phone"
            name="phoneNumber"
            placeholder="Phone Number" 
            register={register} 
            errors={errors} 
            validate={[Validation.required]} 
            readOnly={readOnly}
            format="###-###-####" 
            mask="_"
          />
        </div>

        <div className={style.email}>
          <InputText2
            id="email"
            name="email"
            placeholder="Email" 
            register={register} 
            errors={errors} 
            validate={[Validation.required, Validation.email]}
            readOnly={readOnly}
          />
        </div>

        <div className={style.dot}>
          <InputText2
            id="dot"
            name="dot"
            placeholder="DOT (optional)" 
            register={register} 
            //errors={errors} 
            //validate={[Validation.required]} 
            readOnly={readOnly}
          />
        </div>

        <div className={style.total_power_units}>
          <InputText2
            id="total_power_units"
            name="totalPowerUnits"
            placeholder="Total Power Units" 
            register={register} 
            //errors={errors} 
            //validate={[Validation.required]} 
            onChange={(event) => {
              const {value} = event.target;
              // eslint-disable-next-line 
              event.target.value = value.replace(/[^0-9.\-]/g, "").substr(0,25);
            }}
            readOnly={readOnly}
          />
        </div>

        <div className={style.comprehensive}>
          <InputText2
            id="additional_info"
            name='notes'
            placeholder="Additional Info/Comments" 
            register={register} 
            //errors={errors} 
            //validate={[Validation.required]} 
            readOnly={readOnly}
          />
        </div>


        <div className={style.terms}>
          <Checkbox 
            wClass={style.termsCheck}
            acknowledgment="terms" 
            id="terms" 
            register={register}
            errors={errors} 
            validate={[Validation.required]}
            disabled={readOnly}
          >
            I have read and agree to the <a href={tos} target="_blank" rel="noreferrer">Terms of Use</a> and <a href={privacy} target="_blank" rel="noreferrer">Privacy Policy</a>. Additionally, I authorize you to obtain
            a copy of any Motor Vehicle Report for rating /underwriting purposes and information from other sources, such as a credit report.* 

            {termsToolTip}
          </Checkbox>
        </div>
      </section>

      <div className={style.controls}>
        <div>
          <a href={licensing} target="_blank" rel="noreferrer">Licensing Information</a>
        </div>
        <div className={style.next}>
          <PrimaryButton icon2="m navigate_next" type="submit">Submit</PrimaryButton>
        </div>
      </div>
    </form>
    </Layout>
  );
}

export default UserInformationForm;
