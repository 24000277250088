import React from 'react';

import Layout from 'pages/_common/Layout';
import LicensingInformationOutbound from 'pages/LicensingInformationOutbound';
import LicensingInformationRyder from 'pages/LicensingInformationRyder';

import style from 'styles/pages/LicensingInformation.module.scss';

const LicensingInformation = () => {

  const licensing = {
    'mack': {
      licensing: <LicensingInformationOutbound />,
    },
    'volvo': {
      licensing: <LicensingInformationOutbound />,
    },
    'trux': {
      licensing: <LicensingInformationOutbound />,
    },
    'ryder': {
      licensing: <LicensingInformationRyder />,
    },
  }

  return (
    <Layout wClass={style.layout}>
      <div className={style.page}>
        {licensing[process.env.THEME].licensing}
      </div>
    </Layout>
  )
};

export default LicensingInformation;
